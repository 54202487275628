import React, { Component } from "react";
import PropTypes from "prop-types";

import TextareaAutosize from "react-autosize-textarea";
import Field from "react-bulma-components/lib/components/form/components/field/field";
import Label from "react-bulma-components/lib/components/form/components/label";
import Control from "react-bulma-components/lib/components/form/components/control";
import Input from "react-bulma-components/lib/components/form/components/input";
import Content from "react-bulma-components/lib/components/content/content";
import Heading from "react-bulma-components/lib/components/heading/heading";
import ButtonGroup from "react-bulma-components/lib/components/button/components/button-group";
import Button from "react-bulma-components/lib/components/button/button";

import Styles from "./index.module.scss";
import Overlay from "../overlay";
import { generateMailProgramAnchor } from "../../helper";


export default class Form extends Component {
	static propTypes = {
		subjects: PropTypes.array.isRequired,
		formLabels: PropTypes.any.isRequired,
		dataPrivacyText: PropTypes.any.isRequired
	};

	static defaultProps = {};

	state = {
		valid: false,
		send: false,
		error: false,
		overlayActive: false,

		dataPrivacy: false,
		mail: "",
		subject: "",
		message: ""
	};

	handleChange (event) {
		let { value, name } = event.target;

		if (name === "subject") {
			this.setState({
				...this.state,
				valid: event.target.form.checkValidity(),
				[ name ]: value,
				message: this.findMessageBodyFromSubject(value)
			});
		} else {
			this.setState({
				...this.state,
				valid: event.target.form.checkValidity(),
				[ event.target.name ]: event.target.value
			});
		}
	}

	handleSubmit (event) {
		event.stopPropagation();
		event.preventDefault();

		const
			{ mail, subject, message = this.findMessageBodyFromSubject() } = this.state,
			formData = new FormData();

		formData.append("mail", encodeURIComponent(mail));
		formData.append("subject", encodeURIComponent(subject));
		formData.append("message", encodeURIComponent(message));

		window
			.fetch("/php/send-mail.php", {
				method: "post",
				body: formData
			})
			.then(res => {
				if (!res.ok) {
					throw new Error(res.statusText);
				}

				return res.json();
			})
			.then(() => {
				this.setState({
					...this.state,
					send: true,
					error: false
				});
			})
			.catch(() => {
				this.setState({
					...this.state,
					error: true,
					send: true
				});
			});

		return false;
	}

	findMessageBodyFromSubject () {
		for (const subject of this.props.subjects) {
			if (this.state.subject === subject.label) {
				return subject.messageBody;
			}
		}

		return this.state.message;
	}

	openOverlay () {
		this.setState({
			...this.state,
			overlayActive: true
		});
	}

	closeOverlay () {
		this.setState({
			...this.state,
			overlayActive: false
		});
	}

	handleDataPrivacyClick (event) {
		if (event.target.tagName.toLowerCase() === "a") {
			event.stopPropagation();
			event.preventDefault();

			this.openOverlay();

			return false;
		}
	}

	render () {
		const {
			mailTo,
			mail,
			message,
			subject,
			send,
			dataPrivacyLabel,
			sendSuccess,
			sendSuccessMessage,
			sendErrorOpenMailBtn,
			sendError,
			sendErrorMessage
		} = this.props.formLabels;

		const classNames = [ Styles.formWrapper ];

		if (this.state.send && !this.state.error) {
			classNames.push(Styles.success);
		} else if (this.state.send && this.state.error) {
			classNames.push(Styles.error);
		}

		return (
			<div className={ classNames.join(" ") }>
				{ this.renderOverlay() }

				{ this.state.send &&
					<div className={ Styles.mailSendInfo }>
						<div className={ Styles.icon } />

						<Heading className={ "has-text-centered" }>
							{ this.state.error ?
								sendError:
								sendSuccess
							}
						</Heading>

						<Content className={ Styles.content }>
							<p>
								{ this.state.error ?
									sendErrorMessage:
									sendSuccessMessage
								}
							</p>

							{ this.state.error &&
								<Button
									color={ "black" }
									size={ "medium" }
									renderAs={ "a" }
									href={ this.generateMailAnchor(mailTo) }>
									{ sendErrorOpenMailBtn }
								</Button>
							}
						</Content>
					</div>
				}

				<form
					className={ Styles.formContainer }
					onSubmit={ this.handleSubmit.bind(this) }>

					<div className={ Styles.formContent }>
						<Field className={ Styles.field }>
							<Label
								className={ Styles.label }
								size={ "medium" }>
								{ mail }
							</Label>

							<Control>
								<Input
									className={ Styles.input }
									name={ "mail" }
									type={ "email" }
									size={ "medium" }
									required
									placeholder={ "max@mustermann.de" }
									value={ this.state.mail }
									onChange={ this.handleChange.bind(this) }
								/>
							</Control>
						</Field>

						<Field className={ Styles.field }>
							<Label
								size={ "medium" }
								className={ Styles.label }>
								{ subject }
							</Label>

							<Control>
								{ this.renderSubjectRadioBtns() }
							</Control>
						</Field>

						<Field className={ Styles.field }>
							<Label
								size={ "medium" }
								className={ Styles.label }>
								{ message }
							</Label>

							<TextareaAutosize
								required
								maxRows={ 5 }
								name={ "message" }
								className={ `${ Styles.input } is-medium textarea` }
								onChange={ this.handleChange.bind(this) }
								value={ this.state.message || this.findMessageBodyFromSubject() }
							/>
						</Field>

						<Field className={ Styles.dataPrivacy }>
							<div className={ Styles.wrapper }>
								<input
									name={ "dataPrivacy" }
									id={ "dataPrivacy" }
									size={ "medium" }
									type={ "checkbox" }
									required
									onChange={ this.handleChange.bind(this) }
									value={ this.state.dataPrivacy }
								/>

								<Label
									htmlFor={ "dataPrivacy" }
									className={ [ Styles.label, "label" ].join(" ") }
									onClick={ this.handleDataPrivacyClick.bind(this) }
									dangerouslySetInnerHTML={ { __html: dataPrivacyLabel } }
								/>
							</div>
						</Field>

						<ButtonGroup position={ "centered" }>
							<Button
								className={ Styles.submitBtn }
								submit
								disabled={ !this.state.valid }
								size={ "medium" }
								color={ "dark" }>
								{ send }
							</Button>
						</ButtonGroup>
					</div>
				</form>
			</div>
		);
	}

	renderOverlay () {
		return (
			<Overlay
				className={ Styles.overlayContent }
				active={ this.state.overlayActive }
				onClose={ this.closeOverlay.bind(this) }>
				<Content
					size={ "medium" }
					dangerouslySetInnerHTML={ { __html: this.props.dataPrivacyText } }
				/>
			</Overlay>
		);
	}

	renderSubjectRadioBtns () {
		const { subjects } = this.props;

		return subjects.map(({ label }, id) =>
			<div
				key={ `subjectRadio_${ id }` }
				className={ [ Styles.radioWrapper, this.state.subject === label && Styles.active ].join(" ") }>
				<input
					name={ "subject" }
					id={ `subjectRadio_${ id }` }
					type={ "radio" }
					size={ "medium" }
					required
					className={ Styles.radioInput }
					checked={ this.state.subject === label }
					value={ label }
					onChange={ this.handleChange.bind(this) }
				/>

				<Label
					size={ "medium" }
					className={ Styles.radioLabel }
					htmlFor={ `subjectRadio_${ id }` }>
					{ label }
				</Label>
			</div>
		);
	}

	generateMailAnchor (mailTo) {
		return generateMailProgramAnchor(mailTo, "Anfrage", this.state.message)
	}
}
