import React from "react";

import HandSrc from "./sketch-border-dark.svg";
import Styles from "./index.module.scss";


const SectionSeparator = ({ isBottom = false }) =>
	<img
		src={ HandSrc }
		alt=""
		className={ isBottom ? Styles.sectionCurve : Styles.sectionCurveBottom }
	/>;

export default SectionSeparator;
