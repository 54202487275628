import React, { Component } from "react";
import PropTypes from "prop-types";

import Styles from "./index.module.scss";


export default class Overlay extends Component {
	static propTypes = {
		className: PropTypes.string,
		active: PropTypes.bool,
		onClose: PropTypes.func.isRequired
	};
	
	static defaultProps = {};

	handleCloseClick ({ target }) {
		if (target.classList.contains(Styles.overlay) || target.classList.contains(Styles.closeBtn)) {
			this.props.onClose();
		}
	}

	constructor (props) {
		super(props);

		this.handleKeyDownListener = this.handleKeyDown.bind(this);
	}

	componentDidUpdate (prevProps, prevState, snapshot) {
		if (typeof document === "undefined") {
			return;
		}

		if (this.props.active) {
			document.documentElement.style.overflow = "hidden";
			document.body.style.overflow = "hidden";

			document.addEventListener("keydown", this.handleKeyDownListener);
		} else {
			document.documentElement.style.overflow = "";
			document.body.style.overflow = "";

			document.removeEventListener("keydown", this.handleKeyDownListener);
		}
	}

	handleKeyDown (event) {
		if (event.key === "Escape") {
			this.props.onClose();
		}
	}

	render () {
		const
			containerClasses = [ Styles.container ],
			{ className, active, onClose, ...props } = this.props;

		if (className) {
			containerClasses.push(className);
		}

		return (
			<div
				className={ `${ Styles.overlay } ${ active && Styles.active }` }
				onClick={ this.handleCloseClick.bind(this) }
				{ ...props }>
				<div className={ containerClasses.join(" ") }>
					<div
						onClick={ this.handleCloseClick.bind(this) }
						className={ Styles.closeBtn }
					/>

					{ this.props.active && this.props.children }
				</div>
			</div>
		);
	}
}
