import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";

import Section from "react-bulma-components/lib/components/section/section";
import Columns from "react-bulma-components/lib/components/columns/columns";
import Column from "react-bulma-components/lib/components/columns/components/column";
import Heading from "react-bulma-components/lib/components/heading/heading";
import Container from "react-bulma-components/lib/components/container/container";

import Styles from "./index.module.scss";
import Form from "../../components/form";
import SectionSeparator from "../../components/section-separator";


export default class Contact extends React.Component {
	renderContent (data) {
		return (
			<Section className={ Styles.section }>
				<SectionSeparator />

				<Container>
					<Heading
						size={ 1 }
						className={ Styles.headline }
						renderAs={ "h1" }>
						{ data.mr.fm.section }
					</Heading>

					<Columns
						centered
						className={ Styles.contactDataWrapper }>

						<Column narrow>
							<Image
								className={ Styles.avatarImg }
								fluid={ data.mr.fm.avatar.childImageSharp.fluid }
							/>
						</Column>

						<Column
							narrow
							className={ Styles.contactData }>
							<p
								className={ Styles.instagram}
								dangerouslySetInnerHTML={ { __html: data.mr.fm.contactData.instagram } }
							/>
							<p dangerouslySetInnerHTML={ { __html: data.mr.fm.contactData.mail } } />
							<p>
								<span dangerouslySetInnerHTML={ { __html: data.mr.fm.contactData.phone } } />
								<span> / </span>

								<span
									className={ Styles.whatsapp }
									dangerouslySetInnerHTML={ { __html: data.mr.fm.contactData.whatsapp } }
								/>
								<span> / </span>

								<span
									className={ Styles.telegram }
									dangerouslySetInnerHTML={ { __html: data.mr.fm.contactData.telegram } }
								/>
							</p>
							<p dangerouslySetInnerHTML={ { __html: data.mr.fm.contactData.address } } />
						</Column>
					</Columns>

					<Form
						mailTo={ data.mr.fm.form.mailTo }
						formLabels={ data.mr.fm.form }
						subjects={ data.mr.fm.form.subjectOptions }
						dataPrivacyText={ data.mr.fm.dataPrivacyText.remark.html }
					/>
				</Container>

				<SectionSeparator isBottom />
			</Section>
		);
	}

	render () {
		return (
			<StaticQuery query={ graphql`
				query {
			        mr: markdownRemark(frontmatter: { path: { eq: "contact" } }) {
			            fm: frontmatter {
			                path
							section
							
							avatar {
							    childImageSharp {
									fluid(maxWidth: 400, maxHeight: 400) {
										...GatsbyImageSharpFluid
									}
								}
							}
							
							dataPrivacyText {
								remark: childMarkdownRemark {
									html
								}
							}
							
							contactData {
								mail
								instagram
								address
								phone
								whatsapp
								telegram
							}
							
							form {
								mailTo
								mail
								message
								subject
								send
								dataPrivacyLabel
								dataPrivacyText
								sendSuccess
								sendSuccessMessage
								sendError
								sendErrorMessage
								sendErrorOpenMailBtn
								subjectOptions {
									label
									messageBody
								}
							}
			            }
			        }
			    }` }
                render={ data => this.renderContent(data) }
			/>
		)
	}
}