import React, { Component } from "react";
import ReactDOM from "react-dom";
import Measure from "react-measure";

import { StaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";
import Vimeo from "@u-wave/react-vimeo";
import Section from "react-bulma-components/lib/components/section/section";
import Button from "react-bulma-components/lib/components/button/button";
import Heading from "react-bulma-components/lib/components/heading/heading";
import Content from "react-bulma-components/lib/components/content/content";
import Container from "react-bulma-components/lib/components/container/container";

import MagnifierSrc from "./magnifier-icon.svg";
import { applyHangingPunctuation } from "../../helper";
import Overlay from "../../components/overlay";
import Styles from "./index.module.scss";
import ChevronSrc from "./chevron-down.svg";


export default class Work extends Component {
	state = {
		overlayActive: false,
		activeProject: 0,

		overlayImgFullscreenActive: false,
		teaserImgDimensions: {},
		overlayScrollPosition: 0
	};

	projects = [];

	overlayContentRef = React.createRef();

	handleOverlayScroll (event) {
		this.setState({
			...this.state,
			overlayScrollPosition: event.target.scrollTop 
		});
	}

	renderTeaserImg ({ measureRef }) {
		const
			{ footage, customAccentColor } = this.projects[this.state.activeProject].frontmatter,
			accentColor = customAccentColor || footage.colors.vibrant;

		return (
			<div
				ref={ measureRef }
				style={ {
					backgroundColor: accentColor,
					backgroundImage: `url('${ footage.childImageSharp.fluid.src }')`
				} }
				className={ Styles.teaserImage }
			/>
		)
	}

	componentDidUpdate () {
		if (this.overlayContentRef && this.overlayContentRef.current) {
			const project = this.projects[this.state.activeProject];
			const colorAccent = project.frontmatter.customAccentColor || project.frontmatter.footage.colors.vibrant;
			const colorDark = project.frontmatter.footage.colors.darkMuted;
			const anchors = this.overlayContentRef.current.getElementsByTagName("a");
			const blockquotes = this.overlayContentRef.current.getElementsByTagName("blockquote");

			for (const anchor of anchors) {
				anchor.style.color = colorAccent;
				anchor.target = "_blank";
			}

			for (const bq of blockquotes) {
				bq.style.borderLeftColor = colorDark;
			}
		}
	}

	handleFullscreenClick () {
		this.setState({
			...this.state,
			overlayImgFullscreenActive: false
		});
	}

	handleFullscreenBtnClick () {
		this.setState({
			...this.state,
			overlayImgFullscreenActive: true
		});
	}

	openOverlay (index) {
		this.setState({
			...this.state,
			overlayActive: true,
			activeProject: index
		});
	}

	closeOverlay () {
		this.setState({
			...this.state,
			overlayActive: false,
			overlayImgFullscreenActive: false
		});
	}

	renderOverlayContent ({ name, vimeo, footage, url, customAccentColor }, __html ) {
		if (typeof window === "undefined") {
			return null;
		}

		const accentColor = customAccentColor || footage.colors.vibrant;

		return (
			<React.Fragment>
				{ vimeo ?
					<Vimeo
						className={ Styles.vimeo }
						video={ vimeo }
						paused={ !this.state.overlayActive }
						responsive
					/>:
					<React.Fragment>
						<div className={ Styles.headerImgWrapper }>
							<Image
								className={ Styles.teaserImageMobile }
								fluid={ footage.childImageSharp.fluid }
							/>

							<Button
								onClick={ this.handleFullscreenBtnClick.bind(this) }
								color={ "black" }
								className={ [ Styles.fullscreenBtn, "is-hidden-mobile" ].join(" ") }>
								<img src={ MagnifierSrc } alt={ "Fullscreen Button" } />

								<span>Vergrößern</span>
							</Button>

							<img
								className={ [ Styles.scrollDownInfo, this.state.overlayScrollPosition > 50 && Styles.disabled ].join(" ") }
								src={ ChevronSrc }
								alt=""
							/>

							<Measure bounds client onResize={
								({ bounds }) => this.setState({
									...this.state,
									teaserImgDimensions: bounds
								}) }>
								{ this.renderTeaserImg.bind(this) }
							</Measure>


							{ ReactDOM.createPortal(
								this.renderFullscreenOverlay(),
								document.body
							) }
						</div>

					</React.Fragment>
				}

				<Content
					size={ "medium" }
					className={ Styles.content }>

					{ url &&
						<React.Fragment>
							<a
								href={ url }
								target={ "_blank" }
								rel="noopener noreferrer"
								style={ { backgroundColor: accentColor } }
								className={ Styles.websiteLink }>
								{ vimeo ? "Link öffnen →" : "Website öffnen →" }
							</a>
							
							<hr
								className={ Styles.hr }
								style={ { backgroundColor: accentColor } }
							/>
						</React.Fragment>
					}
					
					<Heading
						renderAs={ "h1" }
						style={ { color: accentColor } }
						className={ Styles.heading }
						size={ 1 }
						dangerouslySetInnerHTML={ { __html: applyHangingPunctuation(name) } }
					/>
					
					<div
						ref={ this.overlayContentRef }
						dangerouslySetInnerHTML={ { __html: applyHangingPunctuation(__html) } }
					/>
				</Content>
			</React.Fragment>
		)
	}

	renderOverlay () {
		const
			project = this.projects[this.state.activeProject],
			{ html, frontmatter } = project;

		return (
			<Overlay
				className={ Styles.overlayContent }
				active={ this.state.overlayActive }
				onClose={ this.closeOverlay.bind(this) }
				onScroll={ this.handleOverlayScroll.bind(this) }>
				{ this.renderOverlayContent(frontmatter, html) }
			</Overlay>
		);
	}

	renderContent (data) {
		this.projects = data.projects.edges.map(({ node }) => node.childMarkdownRemark);

		const { headline, subline } = data.mr.fm;

		return (
			<Section className={ Styles.section }>
				{ this.renderOverlay() }

				<Container>
					<Heading
						className={ Styles.headline }
						renderAs={ "h1" }>
						<span className={ Styles.wrapper }>{ headline }</span>
						<span className={ Styles.subline }>{ subline }</span>
					</Heading>

					<div className={ Styles.projectsContainer }>
						{ this.projects.map((project, index) => {
							const
								{
									footage,
									customAccentColor,
									name
								} = project.frontmatter,
								accentColor = customAccentColor || footage.colors.vibrant;

							return (
								<div
									className={ [ Styles.projectWrapper, !project.html && Styles.disabled ].join(" ") }
									key={ index }
									onClick={ this.openOverlay.bind(this, index) }>

									<div className={ Styles.project }>
										<img
											srcSet={ footage.childImageSharp.fluid.srcSet }
											src={ footage.childImageSharp.fluid.src }
											alt={ name }
										/>

										<div className={ Styles.projectInfos }>
											<Heading
												size={ 6 }
												renderAs={ "h2" }
												className={ "has-margin-none" }>
												{ name }
											</Heading>

											<Button className={ Styles.mobileOpenBtn } color={ "primary" }>
												Öffnen
											</Button>
										</div>

										<div className={ Styles.projectInfoOverlay }>
											<Heading
												renderAs={ "h3" }
												style={ { color: accentColor } }
												className={ Styles.heading }>
												{ name }
											</Heading>
										</div>

										<Button
											color={ "primary" }
											className={ Styles.projectOpenBtn }>
											Projekt öffnen
										</Button>
									</div>
								</div>
							);
						}) }
					</div>
				</Container>
			</Section>
		);
	}

	renderFullscreenOverlay () {
		const
			{ footage, customAccentColor } = this.projects[this.state.activeProject].frontmatter,
			{ top = 0, left = 0, right = 0, bottom = 0 } = this.state.teaserImgDimensions,
			_win = typeof window !== "undefined",
			accentColor = customAccentColor || footage.colors.vibrant;;

		return (
			<div
				onClick={ this.handleFullscreenClick.bind(this) }
				style={ {
					backgroundColor: accentColor,
					backgroundImage: `url("${ footage.childImageSharp.fluid.originalImg }")`,
					top,
					left,
					right: _win && (window.innerWidth - right),
					bottom: _win && (window.innerHeight - bottom)
				} }
				className={ [ Styles.fullscreenSlideshow, this.state.overlayImgFullscreenActive && Styles.active ].join(" ") }>
			</div>
		);
	}

	render () {
		return (
			<StaticQuery query={ graphql`
				query {
			        mr: markdownRemark(frontmatter: { path: { eq: "work" } }) {
			            fm: frontmatter {
			                headline
			                subline
			            }
			        }
			        
					projects: allFile(filter: { relativePath: { glob: "work/*/*/*.md" } } ) {
					    edges {
							node {
								childMarkdownRemark {
									html
									frontmatter {
										name
										type
										description
										url
										features
										vimeo
										footage {
											colors {
												vibrant
												darkMuted
												darkVibrant
												lightVibrant
											}
											childImageSharp {
												fluid {
													aspectRatio
													base64
													originalImg
													originalName
													presentationHeight
													presentationWidth
													sizes
													src
													srcSet
													srcSetWebp
													srcWebp
													tracedSVG
												}
											}
										}
										customAccentColor
									}
								}
							}
					    }
					}
			    }` }
	            render={ this.renderContent.bind(this) }
			/>
		);
	}
}