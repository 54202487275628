import React from "react";
import { graphql } from "gatsby";

import Hero from "../sections/hero";
import Passion from "../sections/passion";
import Work from "../sections/work";
import Contact from "../sections/contact";
import Footer from "../sections/footer";
import Seo from "../components/seo";


export default ({ data }) =>
	<React.Fragment>
		<Seo { ...data.markdownRemark.frontmatter } />

		<Hero />
		<Passion />
		<Work />
		<Contact />
		<Footer />
	</React.Fragment>;

export const query = graphql`
	query {
		markdownRemark (frontmatter: { path: { eq: "meta" } }) {
			frontmatter {
				title
				meta {
					name
					content
				}
			}
		}
	}
`;