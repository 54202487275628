import React from "react";

import { StaticQuery, graphql } from "gatsby";
import Section from "react-bulma-components/lib/components/section/section";
import Columns from "react-bulma-components/lib/components/columns/columns";
import Column from "react-bulma-components/lib/components/columns/components/column";
import Heading from "react-bulma-components/lib/components/heading/heading";
import Content from "react-bulma-components/lib/components/content/content";
import Container from "react-bulma-components/lib/components/container/container";

import SectionSeparator from "../../components/section-separator";
import Styles from "./index.module.scss";


const Passion = () =>
	<StaticQuery query={ graphql`
		query {
	        mr: markdownRemark(frontmatter: { path: { eq: "passion" } }) {
	            fm: frontmatter {
	                title
	                headline
	            }
	            
	            html
	        }
	    }
	` }
	render={ data =>
		<Section className={ Styles.section }>
			<SectionSeparator />

			<Container className={ Styles.container }>
				<Columns centered className={ "is-mobile" }>
					<Column narrow>
						<Heading className={ "has-text-centered" }>
							{ data.mr.fm.headline }
						</Heading>

						<Content
							className={ Styles.description }
							size={ "medium" }
							dangerouslySetInnerHTML={ { __html: data.mr.html } }
						/>
					</Column>
				</Columns>
			</Container>

			<SectionSeparator isBottom />
		</Section>
	} />;


export default Passion;