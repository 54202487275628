import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Section from "react-bulma-components/lib/components/section/section";
import Heading from "react-bulma-components/lib/components/heading/heading";
import Content from "react-bulma-components/lib/components/content/content";


import Styles from "./index.module.scss";
import HandsSrc from "./hands.svg";


const Hero = () =>
	<StaticQuery query={ graphql`
		query {
	        mr: markdownRemark(frontmatter: { path: { eq: "hero" } }) {
	            fm: frontmatter {
	                title
	                section
	                headline
	                subline
	                appendixline
	            }
	            
	            html
	        }
	    }
	` } render={ data =>
		<Section className={ Styles.section }>
			<Heading
				renderAs={ "div" }
				size={ 5 }
				className={ Styles.prefixLine }>
				{ data.mr.fm.subline }
			</Heading>

			<Heading
				renderAs={ "h1"}
				className={ Styles.headline }>
				{ data.mr.fm.headline }
			</Heading>

			<Heading
				renderAs={ "div" }
				size={ 5 }
				className={ Styles.appendixLine }>
				{ data.mr.fm.appendixline }
			</Heading>

			<div className={ Styles.heroImageWrapper }>
				<img
					src={ HandsSrc }
					alt="Bild Startseite (Gezeichnete Hände)"
					className={ Styles.heroImage }
				/>

				<Content dangerouslySetInnerHTML={ { __html: data.html } }  />
			</div>
		</Section>
	} />

export default Hero;