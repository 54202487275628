function shuffleArray (array) {
	let currentIndex = array.length,
		temporaryValue, randomIndex;

	while (0 !== currentIndex) {
		// Pick a remaining element...
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex -= 1;

		temporaryValue = array[currentIndex];
		array[currentIndex] = array[randomIndex];
		array[randomIndex] = temporaryValue;
	}

	return array;
}

function cssPropertyValueSupported (prop, value) {
	const d = document.createElement("div");
	d.style[prop] = value;
	return d.style[prop] === value;
}

function applyHangingPunctuation (string = "") {
	const punctuations = ["„", "»", "“"];

	if (!cssPropertyValueSupported("hanging-punctuation", "first")) {
		for (const punctuation of punctuations) {
			// first letter
			if (string.startsWith(punctuation)) {
				string = `<span style="margin-left: -1ex;">${ punctuation }</span>` + string.substring(1);
				break;
			}
		}

		for (const punctuation of punctuations) {
			const replacement = `<span style="margin-left: -1ex;">${ punctuation }</span>`;

			// other
			string = string.replace(new RegExp(`<br>(\n)?${ punctuation }`, "gi"), "<br>" + replacement);
			string = string.replace(new RegExp(`<p>${ punctuation }`, "gi"), "<p>" + replacement)
		}
	}

	return string;
}

function invertColor (hex) {
	if (hex.indexOf('#') === 0) {
		hex = hex.slice(1);
	}
	// convert 3-digit hex to 6-digits.
	if (hex.length === 3) {
		hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
	}
	if (hex.length !== 6) {
		throw new Error('Invalid HEX color.');
	}
	// invert color components
	const r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
		g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
		b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
	// pad each with zeros and return
	return '#' + padZero(r) + padZero(g) + padZero(b);
}

function padZero(str, len) {
	len = len || 2;
	let zeros = new Array(len).join("0");
	return (zeros + str).slice(-len);
}

function generateMailProgramAnchor (mailTo, subject, message) {
	return `mailto:${ mailTo }?subject=${ encodeURIComponent(subject) }&body=${ encodeURIComponent(message) }`;
}


export {
	invertColor,
	shuffleArray,
	applyHangingPunctuation,
	generateMailProgramAnchor
};